import React from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import queryString from "query-string";
import { toast } from "react-toastify";
import { setRef, getRef } from "../helpers/utils";

// Layout
import Layout from "../components/layout";
import Contain from "../components/contain";
import Cta from "../components/cta";
import Seo from "../components/seo";

// Components
import HeroPage from "../components/hero-page";
import Heading from "../components/heading";
import Plan from "../components/plan";
import Portal from "../components/portal";
import HatIcon from "../components/hat-icon";

// Images
import badge1 from "../images/badges/badge-1.png";
import badge2 from "../images/badges/badge-2.png";
import badge3 from "../images/badges/badge-3.png";

const Badges = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Badge = styled.img`
  max-width: 8rem;
  height: auto;
  margin: 0 0.5rem;

  @media (max-width: 48rem) {
    max-width: 6rem;
  }
`;

const PricingSection = styled.div`
  padding: 0 0 5rem;
`;

const PricingPackage = styled.div`
  padding: 0rem;

  @media (min-width: 64rem) {
    display: flex;
    justify-content: center;
  }
`;

const PlanBtn = styled.button`
  border: 0;
  background: none;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
  outline: none;
  cursor: pointer;

  &:hover {
    color: #3182ce;
  }
`;

const FreeOption = styled.div`
  background: #f5f1eb;
  border-radius: 0.4rem;
  padding: 2rem 4rem 4rem;
  max-width: 52rem;
  margin: 0 auto;
  text-align: center;
`;

const FreeOptionInfo = styled.div`
  font-size: 1;
  font-weight: 600;

  @media (min-width: 30rem) {
    font-size: 1.5rem;
  }
`;

const FreeOptionButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
  border: 0;
  background: none;
  cursor: pointer;
  outline: 0;

  &:hover {
    color: #3182ce;
  }

  @media (min-width: 30rem) {
    font-size: 1.5rem;
  }
`;

// const Calendar = styled.div`
//   margin-top: 2rem;
//   iframe {
//     width: 100%;
//     height: 880px;
//   }
// `;

export const Form = styled.form`
  padding: 1rem;

  label {
    display: block;
    margin: 0 0 0.5rem;
    border-radius: 3px;

    &.error {
      color: #ff0283;
      font-size: 0.8rem;
      text-align: center;
    }

    input {
      background: #ffffff;
      border: 1px solid #ddd;
      border-radius: 0.2rem;
      font-size: 1rem;
      width: 100%;
      padding: 0.875rem;
      outline: none;
      color: #181818;

      &:focus {
        background: #faf5da;
      }
    }

    select {
      width: 100%;
      height: 3rem;
      font-size: 1rem;
      border: 1px solid #ddd;
      background: #fff;
      outline: none;
      color: #181818;
      padding: 0 1rem;

      &:focus {
        background: #faf5da;
      }
    }
  }

  .PhoneInput {
    margin: 0 0 0.5rem;
    input {
      background: #ffffff;
      border: 1px solid #ddd;
      border-radius: 0.2rem;
      font-size: 1rem;
      width: 100%;
      padding: 0.875rem;
      outline: none;
      color: #181818;

      &:focus {
        background: #faf5da;
      }
    }
  }
`;

export const Button = styled.button`
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  padding: 1rem 1.5rem;
  letter-spacing: 0.5px;
  background: #ff0283;
  border-radius: 0.2rem;
  border: 0;
  transition: box-shadow 0.2s ease, border 0.2s ease;
  color: #fff;
  outline: none;
  margin: 1rem 0 0;

  &:hover {
    color: #fff;
    background: #dc0074;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  }
`;

const HaveQuestion = styled.div`
  background: #5521fa;
  padding: 2rem 4rem 4rem;
  margin: 0 auto 5rem;
  text-align: center;
  color: #fff;
  max-width: 52rem;
  border-radius: 0.4rem;

  h2 {
    margin: 0 0 1rem;

    @media (max-width: 30rem) {
      font-size: 1.3rem;
    }
  }

  > div {
    font-size: 1.5rem;
    font-weight: 600;
  }

  button {
    font-size: 1.5rem;
    font-weight: 600;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    border: 0;
    background: none;
    cursor: pointer;
    outline: 0;
    color: #fff;
    transition: all 0.2s;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 30rem) {
      font-size: 1rem;
    }
  }
`;

export default class Pricing extends React.Component {
  state = {
    registerUrl:
      "https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0",
    ref: null,
    planInfo: `<strike>$29</strike> <strong>$26</strong> /month <small>no contract</small>`,
    annual: false,
    visible: false,
    disabled: false,
    phone: null,
  };

  componentDidMount() {
    setRef(this.props.location);
    const ref =
      getRef(this.props.location) ||
      queryString.parse(this.props.location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&ref=${ref}`,
        ref,
      });
    }

    const component = this;
    import("jquery").then(($) => {
      import("jquery-validation").then((validate) => {
        if ($.default) {
          $.default(component.form).validate();
        } else {
          $(component.form).validate();
        }

        component.setState({ disabled: false });
      });
    });

    const s = queryString.parse(window.location.search).s;

    if (s) {
      toast.success(
        "Expect a quick TEXT/Call from us with pricing details within the hour!"
      );
    }
  }

  freeAccountRequest = () => {
    document.location.href = `mailto:contact@quickpageapp.com?subject=${encodeURIComponent(
      "Quickpage free account request"
    )}`;
  };

  planChange = (e) => {
    e.preventDefault();
    const { annual, ref } = this.state;
    if (annual) {
      const registerUrl = ref
        ? `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&ref=${ref}`
        : `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0`;
      this.setState({
        planInfo: `<strike>$29</u> <strike>$26</strong> /month`,
        annual: false,
        registerUrl,
      });
    } else {
      const registerUrl = ref
        ? `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&ref=${ref}`
        : `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0`;
      this.setState({
        planInfo: `$290/year (save $58)`,
        annual: true,
        registerUrl,
      });
    }
  };

  onChangePhone = (phone) => {
    setTimeout(() => {
      this.setState({ phone });
    });
  };

  renderInfo() {
    const { annual } = this.state;

    return annual ? (
      <span>
        Switch to <PlanBtn onClick={this.planChange}>Monthly Plan</PlanBtn>
      </span>
    ) : (
      <span>
        Switch to <PlanBtn onClick={this.planChange}>Annual Plan</PlanBtn> and
        save 2 months
      </span>
    );
  }

  render() {
    const { registerUrl, planInfo, visible, disabled, phone } = this.state;

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Pricing | Quickpage"
          description="Follow up better. Close deals faster. With video."
          keywords="video, email, video email"
          url="https://sutton.quickpage.io/pricing"
        />
        <HeroPage>
          <Contain>
            <Heading textCenter>
              Get the world’s #1 sales application at an unbeatable value
            </Heading>

            <Badges>
              <Badge src={badge1} alt="" />
              <Badge src={badge2} alt="" />
              <Badge src={badge3} alt="" />
            </Badges>
          </Contain>
        </HeroPage>
        <Contain>
          <PricingSection>
            <PricingPackage>
              <Plan
                heading="Individual"
                description="Ideal for individuals who want to customize & scale video communication"
                buttonLabel="7 day FREE trial"
                registerURL={registerUrl}
                price={planInfo}
                features={[
                  "Unlimited customer pages",
                  "4000 Contacts",
                  "Reporting",
                  "Mobile app for Apple & Android",
                  "Google Chrome extension",
                  "Zapier integrations",
                  "<strike>Team manager dashboard</strike>",
                  "<strike>Share leads with team members</strike>",
                  "<strike>Share media folders with team</strike>",
                  "Free migration from another tool",
                  "Email & live chat support",
                  "Facebook support & training",
                ]}
              />
            </PricingPackage>
          </PricingSection>

          <HaveQuestion>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="120"
              fill="none"
            >
              <g fill="#ffffff" clip-path="url(#clip0)">
                <path d="M42.628 57.557l-.716-.142-13.58 8.42 4.16-11.914-1.26-.784c-7.487-4.658-11.78-11.633-11.78-19.136 0-13.396 13.68-24.293 30.495-24.293 11.643 0 21.78 5.227 26.916 12.89 1.56.063 3.087.225 4.577.48C76.232 13.05 64.077 6 49.948 6c-18.86 0-34.205 12.56-34.205 28 0 8.377 4.456 16.13 12.281 21.471l-6.65 19.042 21.26-13.182c2.472.445 4.928.67 7.314.67.523 0 1.044-.013 1.562-.032a24.536 24.536 0 01-2.774-3.707c-2-.07-4.046-.298-6.108-.705z"></path>
                <path d="M75.386 27.667c-13.43 0-24.355 8.99-24.355 20.041S61.957 67.75 75.386 67.75c2.51 0 5.017-.33 7.46-.977L100.93 74.7l-7.397-13.618c4.016-3.675 6.209-8.373 6.209-13.374 0-11.05-10.927-20.04-24.357-20.04zm17.118 39.29l-9.39-4.117-.645.188a25.381 25.381 0 01-7.083 1.012c-11.385 0-20.647-7.327-20.647-16.333 0-9.006 9.263-16.333 20.647-16.333 11.385 0 20.649 7.327 20.649 16.333 0 4.323-2.132 8.404-6.002 11.491l-1.215.97 3.686 6.788z"></path>
                <path d="M63.677 51.148a2.738 2.738 0 100-5.477 2.738 2.738 0 000 5.477zm11.709 0a2.738 2.738 0 100-5.477 2.738 2.738 0 000 5.477zm11.708 0a2.738 2.738 0 100-5.477 2.738 2.738 0 000 5.477zm15.217 30.546h-1.484c-3.33 0-6.055 2.725-6.055 6.055v3.007c0 3.33 2.725 6.055 6.055 6.055h1.484c3.33 0 6.055-2.725 6.055-6.055v-3.007c0-3.331-2.725-6.055-6.055-6.055zm2.346 9.06c0 1.273-1.074 2.347-2.346 2.347h-1.484c-1.272 0-2.346-1.074-2.346-2.346v-3.007c0-1.272 1.074-2.346 2.346-2.346h1.484c1.272 0 2.346 1.074 2.346 2.346v3.007zm-6.425 9.11c-4.328 0-7.869 3.541-7.869 7.868V114h22.41v-6.269c0-4.327-3.541-7.868-7.868-7.868h-6.673v.001zm10.834 7.867v2.56H94.073v-2.56a4.164 4.164 0 014.159-4.159h6.674a4.164 4.164 0 014.16 4.159zM11.633 87.748v3.007c0 3.33 2.725 6.055 6.055 6.055h1.484c3.33 0 6.056-2.725 6.056-6.055v-3.007c0-3.33-2.726-6.056-6.056-6.056h-1.484c-3.33.002-6.055 2.726-6.055 6.056zm3.709 0c0-1.272 1.074-2.346 2.346-2.346h1.484c1.272 0 2.346 1.074 2.346 2.346v3.007c0 1.272-1.074 2.346-2.346 2.346h-1.484c-1.272 0-2.346-1.074-2.346-2.346v-3.007zm-8.117 19.983V114h22.41v-6.269c0-4.327-3.542-7.868-7.869-7.868h-6.674c-4.327.001-7.867 3.541-7.867 7.868zm3.708 0a4.164 4.164 0 014.159-4.159h6.674a4.164 4.164 0 014.16 4.159v2.56H10.932v-2.56z"></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <path fill="#fff" d="M0 0h120v120H0z"></path>
                </clipPath>
              </defs>
            </svg>
            <h2>Have questions about Quickpage?</h2>
            <div>
              <button onClick={() => window.Intercom("show")}>
                Chat with us now.
              </button>{" "}
            </div>
          </HaveQuestion>
        </Contain>

        <Cta location={this.props.location} />
        {visible && (
          <Portal
            title="Request team pricing"
            description="Enter your info for team pricing"
            onClicked={() => this.setState({ visible: false })}
          >
            <Form
              method="POST"
              ref={(form) => (this.form = form)}
              action="https://quickpage.activehosted.com/proc.php"
              noValidate=""
            >
              <input type="hidden" name="u" value="5" />
              <input type="hidden" name="f" value="5" />
              <input type="hidden" name="s" />
              <input type="hidden" name="c" value="0" />
              <input type="hidden" name="m" value="0" />
              <input type="hidden" name="act" value="sub" />
              <input type="hidden" name="v" value="2" />
              <label>
                <input
                  type="text"
                  name="fullname"
                  ref={(i) => (this.nameInput = i)}
                  placeholder="Full Name"
                  required
                />
              </label>

              <label>
                <input
                  type="text"
                  name="email"
                  ref={(i) => (this.emailInput = i)}
                  placeholder="Email"
                  required
                />
              </label>

              <PhoneInput
                placeholder="Phone"
                name="phone"
                value={phone}
                type="text"
                defaultCountry="US"
                countries={["US", "AU", "CA"]}
                onChange={this.onChangePhone}
                required={true}
              />

              <label>
                <select
                  name="field[6]"
                  ref={(i) => (this.industrySelect = i)}
                  required
                >
                  <option value="" disabled selected>
                    Select your Industry
                  </option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Consulting">Consulting</option>
                  <option value="Coaching">Coaching</option>
                  <option value="Mortgage">Mortgage</option>
                  <option value="MLM">MLM</option>
                  <option value="Sales">Sales</option>
                  <option value="Other">Other</option>
                </select>
              </label>

              <label>
                <input
                  type="text"
                  name="field[2]"
                  placeholder="Company name"
                  ref={(i) => (this.teamNameInput = i)}
                />
              </label>

              <label>
                <select
                  name="field[7]"
                  ref={(i) => (this.teamSizeSelect = i)}
                  required
                >
                  <option value="" disabled selected>
                    Select your Team Size
                  </option>
                  <option value="2-5">2-5</option>
                  <option value="6-10">6-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21+">21+</option>
                </select>
              </label>

              <Button type="submit" disabled={disabled}>
                Get Team PRICING NOW
              </Button>
            </Form>
          </Portal>
        )}
      </Layout>
    );
  }
}
