import React from "react";
import styled from "styled-components";

const Plan = styled.div`
  margin: 0 auto 2rem;
  background: #fff;
  padding: 2rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);

  @media (min-width: 64rem) {
    max-width: 25rem;
    margin: 0 1rem 2rem;
  }
`;

const Heading = styled.h3`
  font-size: 2rem;
  font-weight: 600;
`;

const Description = styled.p`
  padding: 1rem 0;
`;

const Button = styled.button`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1rem;
  background: #fff;
  color: #181818;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  text-align: center;
  margin: 0 0 1rem;
  border: 2px solid #181818;
  width: 100%;
  height: 3rem;
  cursor: pointer;

  &:last-of-type {
    margin: 0;
  }

  &:hover {
    background: #181818;
    color: #fff;
  }
`;

const ButtonLink = styled.a`
  font-weight: 600;
  padding: 0.8rem 1rem;
  background: #ff0283;
  color: #fff;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  text-align: center;
  display: block;
  margin: 0 0 1rem;
  height: 3rem;

  &:last-of-type {
    margin: 0;
  }

  &:hover {
    background: #dc0074;
    color: #fff;
  }
`;

const Price = styled.div`
  font-size: 1.5rem;

  @media (min-width: 64rem) {
    ${(props) =>
      props.updateHeight &&
      `
      height: 2.35rem;
    `}
  }
`;

const Info = styled.div`
  padding: 1rem 0 0.5rem;
  button: {
    border: 0;
    background: none;
    font-weight: 600;
  }
`;

const Line = styled.div`
  height: 1px;
  background: #eee;
  margin: 2rem -2rem;
`;

const FeaturesHeading = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #5e5e5e;
  margin: 0 0 1rem;
`;
const Features = styled.ul`
  margin: 0 0 2rem;
`;
const Feature = styled.li`
  line-height: 2rem;
  strike {
    color: #b0b8be;
  }
`;

const Learn = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #ff0283;
  margin-top: 0.5rem;
  text-align: center;
`;

export default ({
  heading,
  description,
  buttonLabel,
  registerURL,
  onClick,
  price = "",
  info,
  features,
}) => {
  return (
    <Plan>
      <Heading>{heading}</Heading>

      <Description>{description}</Description>
      {registerURL ? (
        <span>
          <ButtonLink href={registerURL}>{buttonLabel}</ButtonLink>
        </span>
      ) : (
        <Button onClick={onClick}>{buttonLabel}</Button>
      )}

      <Info>{info}</Info>

      <Price
        updateHeight={registerURL ? false : true}
        dangerouslySetInnerHTML={{ __html: price }}
      />

      <Line />

      <FeaturesHeading>Features:</FeaturesHeading>
      <Features>
        {features.map((f) => (
          <Feature
            key={Math.random()}
            dangerouslySetInnerHTML={{ __html: f }}
          />
        ))}
      </Features>

      {registerURL ? (
        <span>
          <ButtonLink href={registerURL}>{buttonLabel}</ButtonLink>
        </span>
      ) : (
        <Button onClick={onClick}>{buttonLabel}</Button>
      )}
    </Plan>
  );
};
