import React from "react";

export default ({ children, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      fill="none"
    >
      <path
        fill="#181818"
        d="M113.575 49.252L60.608 27.697a1.592 1.592 0 00-1.237 0L6.425 49.252a1.639 1.639 0 00.037 3.052l21.361 8.103v16.405c0 8.027 15.636 15.617 32.171 15.617 16.54 0 32.176-7.59 32.176-15.617V60.598l11.59-4.494v12.718c-1.962.687-3.39 2.513-3.39 4.706 0 1.846 1.052 3.404 2.538 4.282l-2.287 3.641a1.644 1.644 0 001.39 2.513h6.795c.033 0 .058-.006.084 0 .904 0 1.641-.737 1.641-1.642 0-.493-.217-.936-.564-1.237l-2.063-3.276c1.493-.878 2.545-2.437 2.545-4.283 0-2.192-1.436-4.02-3.405-4.705V54.828l6.507-2.526a1.65 1.65 0 001.045-1.52 1.634 1.634 0 00-1.021-1.53zM88.888 76.81c0 4.853-12.406 12.335-28.894 12.335-16.482 0-28.887-7.482-28.887-12.335V61.649l28.92 10.969a1.636 1.636 0 001.178 0l27.683-10.745v14.938zm18.277-3.282a1.76 1.76 0 01-1.763 1.757 1.754 1.754 0 01-1.751-1.757c0-.969.781-1.757 1.751-1.757a1.76 1.76 0 011.763 1.757zM60.602 69.33L11.535 50.718l48.459-19.733 48.516 19.746L60.602 69.33z"
      ></path>
    </svg>
  );
};
