import React, { Component } from "react";
import ReactDOM from "react-dom";

import { Modal, ModalContain, Button, Title, Description } from "./styles";

export default class Portal extends Component {
  portalRoot =
    typeof document !== `undefined` ? document.getElementById("portal") : null;
  el = typeof document !== `undefined` ? document.createElement("div") : null;

  componentDidMount() {
    this.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.el);
  }

  renderModal() {
    const { children, title, description, onClicked } = this.props;
    return (
      <Modal>
        <ModalContain>
          <Button type="button" onClick={onClicked}>
            ×
          </Button>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {children}
        </ModalContain>
      </Modal>
    );
  }

  render() {
    if (this.el) {
      return ReactDOM.createPortal(this.renderModal(), this.el);
    } else {
      return null;
    }
  }
}
