import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  overflow: auto;

  .video-fluid-contain {
    max-width: 640px;
    margin: 0 auto;
  }
  .video-fluid {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ModalContain = styled.div`
  width: 90%;
  max-width: 64rem;
  margin: 1rem auto;
  background: #fff;
  position: relative;
  padding: 1rem;
`;

export const Button = styled.button`
  background: none;
  border: 0;
  color: #181818;
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  line-height: 32px;
  cursor: pointer;
  outline: none;
`;
export const Title = styled.h2`
  text-align: center;
  padding: 1rem 0 0.5rem;
`;
export const Description = styled.p`
  text-align: center;
  color: #5e5e5e;
  font-size: 1rem;
  padding: 0 0 1rem;

  @media (min-width: 50rem) {
    font-size: 1.5rem;
  }
`;
